
import { defineComponent } from "vue";

const imageClickAmount = 50;

interface AboutData {
  clickCount: 0;
}
export default defineComponent({
  name: "About",
  data(): AboutData {
    return {
      clickCount: 0,
    };
  },
  methods: {
    handleImageClick() {
      this.clickCount += 1;
      if (this.clickCount === imageClickAmount) {
        alert(
          "Your clicks are not fast enough. You have to click faster. Try again!",
        );
      }
      if (this.clickCount === imageClickAmount * 2) {
        alert(
          "Your clicks are not fast enough. Difficulty got decreased. Try again!",
        );
      }
      if (this.clickCount === imageClickAmount * 3) {
        alert("You really clicked another 50 times? 🤣");
        alert("That was fun 😈");
        alert("There are no facts you can activate");
        alert("haha");
        alert("haha haha");
        alert("haha haha haha");
        this.clickCount = 0;
      }
    },
  },
});
